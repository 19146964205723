<template>
  <div class="sonHome">
    <div class="cell">
      <div class="title">
        <div>我的商家和店铺</div>
        <div>
          <el-button @click="
              dialogVisible = true;
              shopName = '';
            " icon="el-icon-office-building" type="primary" size="small">添加商家</el-button>
        </div>
      </div>
      <div class="cancel-tab">
        <el-popover placement="bottom-start" width="310" v-model="visible" trigger="manual">
          <div>通过切换菜单，您可以分别查看已开店的商家 以及未开店的商家</div>
          <div style="
              margin-top: 20px;
              padding: 0 60px;
              display: flex;
              justify-content: space-between;
            ">
            <el-button @click="handleClick(2)" size="small" type="primary">确定</el-button>
            <el-button size="small" @click="handelCloss">关闭</el-button>
          </div>
          <el-tabs slot="reference" v-model="activeName">
            <el-tab-pane label="已开店的商家" name="0"> </el-tab-pane>
            <!-- <el-tab-pane label="未开店的商家" name="1"> </el-tab-pane> -->
          </el-tabs>
        </el-popover>
      </div>

      <div class="content" v-if="activeName == 0">
        <div class="cancel-tab-one">
          <el-popover placement="bottom-start" trigger="manual" v-model="visible1" width="310">
            <div>通过切换状态栏，您可以查看不同授权状态的店铺</div>
            <div style="
                margin-top: 20px;
                padding: 0 60px;
                display: flex;
                justify-content: space-between;
              ">
              <el-button @click="handleClick(3)" size="small" type="primary">确定</el-button>
              <el-button size="small" @click="
                  show = false;
                  visible1 = false;
                ">关闭</el-button>
            </div>
            <el-tabs slot="reference" @tab-click="qiehuan" v-model="activeNames" type="card">
              <el-tab-pane label="已授权的商店" name="1"></el-tab-pane>
              <el-tab-pane label="待授权的商店" name="0"></el-tab-pane>
              <el-tab-pane label="已过期的店铺" name="2"></el-tab-pane>
              <el-tab-pane label="已拒绝的店铺" name="3"></el-tab-pane>
            </el-tabs>
          </el-popover>
        </div>

        <div>
          <el-form ref="form" :model="form" label-width="80px" label-position="top">
            <div style="display: flex; justify-content: space-between">
              <el-form-item label="商家名称">
                <el-input style="min-width: 260px" size="medium" v-model="form.merchant_name"></el-input>
              </el-form-item>
              <el-form-item label="商店名称">
                <el-input style="min-width: 260px" size="medium" v-model="form.shop_name"></el-input>
              </el-form-item>
              <el-form-item label="店铺地区">
                <el-input style="min-width: 260px" size="medium" v-model="form.shop_addr"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="handleSearch" size="medium">搜索</el-button>
          <el-button size="medium" @click="handleReset">重设</el-button>
        </div>
        <div class="tishi" v-if="activeNames == 0">
          <div style="margin-right: 10px">
            <i class="el-icon-warning" style="font-size: 18px; color: #ffc30e"></i>
          </div>
          <div>
            <div>您有{{ listData.length }}家商店需要被授权</div>
            <div>
              未授权的商店无法被主账号或子账号管理。请在72小时内授权您的商店。
            </div>
          </div>
        </div>

        <div class="list">
          <div class="item">
            <div class="title">
              <div>商家和店铺（{{ listData.length }}家）</div>
              <div>操作</div>
            </div>
            <div v-if="listData.length != 0">
              <div v-for="item in listData" :key="item.id">
                <div class="nr">
                  <div class="nr-left">
                    <i v-if="item.show" class="el-icon-arrow-up" style="cursor: pointer" @click="handleShow(item)"></i>
                    <i v-else class="el-icon-arrow-down" style="cursor: pointer" @click="handleShow(item)"></i>
                    <svg t="1655444646042" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="2037" width="24" height="24" style="margin: 0 10px">
                      <path d="M593.688 222H334.5c-40.042 0-72.5 32.458-72.5 72.5V802" fill="#4988FD" p-id="2038">
                      </path>
                      <path
                        d="M292 802h-60V294.5C232 237.982 277.982 192 334.5 192h259.188v60H334.5c-23.434 0-42.5 19.066-42.5 42.5V802z"
                        fill="#4988FD" p-id="2039"></path>
                      <path d="M328 222h259.188c40.042 0 72.5 32.458 72.5 72.5v504.29H259.622" fill="#4988FD"
                        p-id="2040"></path>
                      <path
                        d="M689.688 828.79H259.622v-60h370.066V294.5c0-23.434-19.064-42.5-42.5-42.5H328V192h259.188c56.52 0 102.5 45.982 102.5 102.5v534.29z"
                        fill="#4988FD" p-id="2041"></path>
                      <path d="M590 512h152c37.554 0 68 30.446 68 68v252H522V580c0-37.554 30.446-68 68-68z"
                        fill="#4988FD" p-id="2042"></path>
                      <path
                        d="M750 832h-168c-33.084 0-60-26.916-60-60v-192c0-37.496 30.504-68 68-68h152c37.496 0 68 30.504 68 68v192c0 33.084-26.916 60-60 60z m-160-260c-4.412 0-8 3.588-8 8v192h168a0.26 0.26 0 0 0 0.038-0.002L750 580c0-4.412-3.588-8-8-8h-152z"
                        fill="#DFECFD" p-id="2043"></path>
                      <path d="M522 643h288v60H522z" fill="#DFECFD" p-id="2044"></path>
                      <path
                        d="M636 512h60v290h-60zM368 332h60a12 12 0 0 1 12 12v60a12 12 0 0 1-12 12h-60a12 12 0 0 1-12-12v-60a12 12 0 0 1 12-12zM368 476h60a12 12 0 0 1 12 12v60a12 12 0 0 1-12 12h-60a12 12 0 0 1-12-12v-60a12 12 0 0 1 12-12zM368 620h60a12 12 0 0 1 12 12v60a12 12 0 0 1-12 12h-60a12 12 0 0 1-12-12v-60a12 12 0 0 1 12-12zM492 332h60a12 12 0 0 1 12 12v60a12 12 0 0 1-12 12h-60a12 12 0 0 1-12-12v-60a12 12 0 0 1 12-12z"
                        fill="#DFECFD" p-id="2045"></path>
                      <path d="M192 772h640v60H192z" fill="#4988FD" p-id="2046"></path>
                    </svg>
                    <span>{{ item.company_name }}</span>
                    <el-popover placement="top-end" width="200" trigger="hover" v-if="item.status == 1">
                      <div>店铺已升级，请通过主/子账号登录CNSC进行管理。</div>
                      <div style="display: flex; justify-content: right">
                        <div class="btn" style="
                            margin-top: 14px;
                            font-size: 8px;
                            background: #ee4d2d;
                            padding: 2px 4px;
                            color: #fff;
                            border-radius: 2px;
                            cursor: pointer;
                          " @click="toLogin">
                          登录CNSC
                        </div>
                      </div>
                      <span class="tag" slot="reference">
                        <svg t="1655444871704" class="icon" viewBox="0 0 1024 1024" version="1.1"
                          xmlns="http://www.w3.org/2000/svg" p-id="3921" width="10" height="10"
                          style="margin-right: 4px">
                          <path
                            d="M511.999488 67.472717c-245.505483 0-444.526259 199.021799-444.526259 444.527283s199.020776 444.526259 444.526259 444.526259S956.525748 757.505483 956.525748 512 757.504972 67.472717 511.999488 67.472717zM720.358957 798.782585 511.999488 647.400806 303.638997 798.782585l79.586616-244.941642L174.865121 402.458141l257.547751 0 79.586616-244.941642 79.585593 244.941642 257.548774 0L640.773364 553.840944 720.358957 798.782585z"
                            p-id="3922" fill="#1BAF9D"></path>
                        </svg>
                        <span>已升级</span>
                      </span>
                    </el-popover>
                  </div>
                  <div>
                    <el-popover placement="bottom" width="auto" trigger="click" v-if="item.status == 1">
                      <div style="cursor: pointer; text-align: center" class="active" @click="handleRemove(item)">
                        解除绑定
                      </div>
                      <i slot="reference" style="cursor: pointer" class="el-icon-more"></i>
                    </el-popover>
                  </div>
                </div>
                <div class="nr-hidden" v-if="item.show">
                  <div style="display: flex; align-items: center; width: 100%">
                    <svg data-v-2a24c92c="" t="1655358522119" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="2050" width="16" height="16" class="icon"
                      style="margin-right: 20px">
                      <path data-v-2a24c92c=""
                        d="M952.786158 352.800941c0-0.516042-0.172014-0.860071-0.172014-1.376113-0.344028-1.892155-0.860071-3.612296-1.548127-5.332437L875.895851 159.285066c-12.901058-38.531161-48.852007-62.613136-93.919704-62.613136L255.440954 96.67193c-44.207626 0-78.610449 23.565933-90.995464 60.720981L83.770872 347.984546c-0.344028 1.204099-0.860071 2.752226-1.204099 4.472367-5.84848 18.061482-8.77272 36.466991-8.77272 55.044515 0 65.193348 35.778935 124.71023 93.231648 155.328742 23.565933 12.729044 51.432219 19.093566 82.738787 19.093566 0 0 0 0 0.172014 0 52.464304-0.172014 101.144297-23.221905 134.34302-62.613136 33.026709 39.219217 81.19066 62.097094 133.654964 62.441122 51.948261-0.344028 100.112212-23.393919 132.966907-62.613136 33.198723 39.563245 81.878717 62.441122 134.687049 62.441122 31.994625-0.172014 60.204939-6.880564 84.1149-19.953637 56.592642-30.96254 91.683521-90.135394 91.683521-154.640685C961.386864 388.235847 958.29061 369.658324 952.786158 352.800941zM766.322862 383.935495 254.408869 383.935495c-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625l512.086007 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S784.040316 383.935495 766.322862 383.935495z"
                        p-id="2051" fill="#B7B7B7"></path>
                      <path data-v-2a24c92c=""
                        d="M894.989417 639.892491c0-17.717453-14.277171-31.994625-31.994625-31.994625-3.268268 0-6.192508 0.860071-9.288762 1.892155l0 0c-23.737947 9.460776-54.012431 14.449185-76.890307 13.245087-50.400134-2.752226-88.931295-12.729044-125.914329-44.207626-36.466991 31.134554-83.082815 48.852007-132.622879 49.36805-50.22812-0.344028-97.187972-12.901058-133.826978-44.207626-36.983034 31.478582-84.1149 46.787838-134.34302 46.787838l-0.172014 0c-30.618512 0-47.991937-5.84848-75.858223-16.169326-0.688056-0.344028-1.548127-0.516042-2.408198-0.860071-1.376113-0.516042-2.408198-0.860071-3.78431-1.376113 0 0-4.644381-0.860071-7.052579-0.860071 0 0 0 0 0 0-17.717453 0-31.994625 14.449185-31.994625 31.994625l0.172014 171.498068c0 45.067697 36.81102 81.878717 82.050731 81.878717l602.221401-0.344028c45.067697-0.172014 81.878717-36.983034 81.878717-82.050731L894.989417 639.892491z"
                        p-id="2052" fill="#B7B7B7"></path>
                    </svg>
                    <div style="width: 30%">{{ item.shop_name }}</div>
                    <div style="width: 20%">{{ item.province }}</div>
                    <div style="width: 20%" v-if="item.status == 1">
                      {{ item.member_count }}成员
                    </div>
                    <div v-if="item.status == 1">
                      {{ item.authorize_time }} 已绑定
                    </div>
                    <div v-if="item.status == 0">
                      {{ item.expire_time }} 将会过期
                    </div>
                  </div>
                  <div style="padding-left: 20px">
                    <div v-if="item.status == 0" style="width: 30px; color: #2673e2; cursor: pointer"
                      @click="handleAuth(item)">
                      授权
                    </div>
                    <div v-if="item.status == 3" style="width: 30px; color: #2673e2; cursor: pointer"
                      @click="handleReqAuth(item)">
                      重新授权
                    </div>
                    <!-- <el-popover
                      placement="bottom"
                      width="auto"
                      trigger="click"
                      v-if="item.status == 1"
                    >
                      <div
                        style="cursor: pointer; text-align: center"
                        class="active"
                      >
                        详情
                      </div>
                      <i
                        slot="reference"
                        style="cursor: pointer"
                        class="el-icon-more"
                      ></i>
                    </el-popover> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="listData.length == 0" style="text-align: center; margin-top: 20px">
          <svg t="1655457702912" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="13357" width="100" height="100">
            <path
              d="M768 302.848l-32 29.216V128H128v768h608v-166.752l32-30.464V896a32 32 0 0 1-32 32H128a32 32 0 0 1-32-32V128a32 32 0 0 1 32-32h608a32 32 0 0 1 32 32v174.848z m-30.464 125.536L480 685.92V768h114.304l14.848-15.552-87.776-87.776 22.624-22.656 35.68 35.68 204.576-204.576-46.72-44.736z m22.624-22.624l94.112 90.112 46.784-48.96-91.008-91.04-49.888 49.888z m72 113.248l-24.8-23.744-205.056 205.056 28.96 28.992 200.928-210.304z m-22.112-208.384l135.744 135.776L608 800h-160v-127.36L810.048 310.656zM224 256h416v32H224V256z m0 128h416v32H224v-32z m0 128h288v32H224v-32z m0 256h160v32H224v-32z"
              p-id="13358" fill="#b9b9b9"></path>
          </svg>
          <div style="color: #b9b9b9; padding-right: 14px">暂无商家</div>
        </div>
      </div>
      <div class="content" v-if="activeName == 1">234</div>
    </div>
    <div class="float" v-if="show"></div>
    <el-dialog :visible.sync="dialogVisible" width="500px" @close="handleClose" :close-on-click-modal="false">
      <span v-if="addStep == 1">
        <div style="text-align: center">
          <div style="font-size: 20px; margin-bottom: 20px">查找商家</div>
          <div style="margin-bottom: 20px">
            添加后，商家的商店将与您的主账户绑定
          </div>
          <div style="color: #ee4d2d; margin-bottom: 20px">{{ msg }}</div>
          <div>
            <el-input @input="msg = ''" style="max-width: 380px" placeholder="输入店铺名称" v-model="shopName">
              <template slot="prepend">
                <i class="el-icon-search"></i>
              </template>
            </el-input>
          </div>
        </div>
        <div class="dialog-footer" style="text-align: center; padding-bottom: 20px">
          <el-button style="min-width: 380px; margin-top: 30px" type="primary" :disabled="shopName == ''"
            @click="FindShop(1)">查找</el-button>
        </div>
      </span>
      <span v-if="addStep == 2">
        <div style="text-align: center">
          <div style="font-size: 20px; margin-bottom: 20px">
            选择您的商家以继续
          </div>

          <div style="display: flex; padding: 0 40px">
            <div style="
                width: 18px;
                height: 18px;
                background: #fff;
                border: 5px solid #f17157;
                border-radius: 50%;
                margin-right: 20px;
              "></div>
            <div style="text-align: left; font-size: 16px">
              <div>{{ selectedShop.name }}</div>
              <div style="color: #b0b0b0">
                {{ selectedShop.email ? selectedShop.email + "," : "" }}
                {{
                "+86 " +
                selectedShop.mobile.slice(0, 3) +
                "****" +
                selectedShop.mobile.slice(-4, selectedShop.mobile.length)
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer" style="text-align: center; padding-bottom: 20px">
          <el-button style="min-width: 380px; margin-top: 30px" type="primary" :disabled="shopName == ''"
            @click="FindShop(2)">继续</el-button>
        </div>
      </span>
      <span v-if="addStep == 3">
        <div style="text-align: center">
          <div style="font-size: 20px; margin-bottom: 20px">
            验证该商家以继续
          </div>

          <div style="
              display: flex;
              background: #f5f5f5;
              margin: 0 20px;
              padding: 20px 20px;
            ">
            <div style="
                width: 18px;
                height: 18px;
                background: #fff;
                border: 5px solid #f17157;
                border-radius: 50%;
                margin-right: 20px;
              "></div>
            <div style="text-align: left; font-size: 16px">
              <div>发送验证码至电话</div>
              <div>
                {{
                "+86 " +
                selectedShop.mobile.slice(0, 3) +
                "****" +
                selectedShop.mobile.slice(-4, selectedShop.mobile.length)
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer" style="text-align: center; padding-bottom: 20px">
          <el-button style="min-width: 380px; margin-top: 30px" type="primary" :disabled="shopName == ''"
            @click="FindShop(3)">继续</el-button>
        </div>
      </span>
      <span v-if="addStep == 4">
        <div style="text-align: center">
          <div style="font-size: 20px; margin-bottom: 20px">输入验证码</div>

          <div>
            请输入发送到商家注册手机+86
            {{
            "+86 " +
            selectedShop.mobile.slice(0, 3) +
            "****" +
            selectedShop.mobile.slice(-4, selectedShop.mobile.length)
            }}
          </div>
          <div>
            <vue-sms-check-code title=" " :code-num="6" :is-error="isError" error-color="#ee4d2d" @finish="getSmsCodes"
              style="width: 300px; font-size: 16px" />
          </div>
          <div style="font-size: 14px; color: green">
            验证码：{{ smsCodeText }}
          </div>
          <div style="color: #b0b0b0; font-size: 14px; margin: 30px 0">
            <span v-if="num != 60"> 请稍等{{ num }}秒后重新发送</span>
            <span v-else style="color: #ee4d2d; text-decoration: underline" @click="getSmsCodeF">重新发送</span>
          </div>
        </div>
        <div class="dialog-footer" style="text-align: center; padding-bottom: 20px">
          <el-button style="min-width: 380px; margin-top: 30px" type="primary" :disabled="shopName == ''"
            @click="FindShop(4)">验证</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { merchantList, unbind, findMerchant, addMerchant, authorization } from "@/api/main.js";
  import { getSmsCode } from "@/api/common.js";

  export default {
    data() {
      return {
        activeName: "0",
        activeNames: "1",
        form: {
          merchant_name: "",
          shop_name: "",
          shop_addr: "",
        },
        show: false,
        visible: false,
        visible1: false,
        listData: [],
        dialogVisible: false,
        shopName: "",
        msg: "",
        addStep: 1,
        selectedShop: "",
        isError: false,
        smsCodeText: "",
        smsCode: "",
        num: 59,
      };
    },
    created() {
      if (this.$route.query.type == "AUTH") {
        this.activeNames = "0";
      }
      // if (localStorage.PROMPTSHOW) {
      //   this.show = localStorage.PROMPTSHOW == 1 ? false : true;
      // }
    },
    mounted() {
      if (!localStorage.PROMPTSHOW) {
        this.handleClick(1);
      }

      this.getList();
    },
    methods: {
      getList() {
        merchantList({
          status: this.activeNames,
          ...this.form,
        }).then((res) => {
          if (res.code == 1) {
            this.listData = res.data;
            // let data = JSON.stringify(res.data[0]);
            // this.listData.push(JSON.parse(data));
            // this.listData[1].id = 3;
            this.listData.forEach((item) => {
              item.show = true;
            });
          }
        });
      },
      handleClick(type) {
        let box1 = document.getElementsByClassName("el-tabs__nav-scroll");
        if (type == 1) {
          this.show = true;
          box1[0].setAttribute(
            "style",
            "background:#fff;z-index:99;    position: relative; "
          );
          this.visible = true;
        }
        if (type == 2) {
          this.visible1 = true;
          box1[0].setAttribute(
            "style",
            "background:#fff;z-index:1;    position: static; "
          );
          box1[1].setAttribute(
            "style",
            "background:#fff;z-index:99;    position: relative; "
          );
          this.visible = false;
        }
        if (type == 3) {
          this.visible1 = false;
          this.show = false;
          localStorage.PROMPTSHOW = 1;
          box1[1].setAttribute(
            "style",
            "background:transparent;z-index:1;    position: static; "
          );
        }
      },
      handelCloss() {
        let box1 = document.getElementsByClassName("el-tabs__nav-scroll");
        box1[0].setAttribute(
          "style",
          "background:#fff;z-index:1;    position: static; "
        );
        box1[1].setAttribute(
          "style",
          "background:transparent;z-index:1;    position: static; "
        );
        this.visible = false;
        this.show = false;
      },
      qiehuan() {
        this.getList();
      },
      handleSearch() {
        this.getList();
      },
      handleReset() {
        this.form = {
          merchant_name: "",
          shop_name: "",
          shop_addr: "",
        };
        this.getList();
      },
      handleShow(data) {
        data.show = data.show ? false : true;
        this.$forceUpdate();
      },
      handleAuth(data) {
        sessionStorage.AUTHDATA = JSON.stringify(data);
        this.$router.push("sonAuth");
      },
      handleRemove(item) {
        this.$confirm(
          "一旦取消绑定，该商家和其名下所有店铺将会解绑，你确定取消绑定吗？",
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
          }
        ).then(() => {
          unbind({
            id: item.id,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.message);
              this.activeNames = "0";
              this.getList();
            }
          });
        });
      },
      FindShop(step) {
        if (step == 1) {
          findMerchant({
            keyword: this.shopName,
          }).then((res) => {
            if (res.code == 1) {
              if (res.data == null) {
                this.$message.error("没有找到对应的商家");
                this.msg = "店铺不存在";
              } else {
                this.msg = "";
                this.addStep = 2;
                this.selectedShop = res.data;
              }
            }
          });
        }
        if (step == 2) {
          this.addStep = 3;
        }
        if (step == 3) {
          this.getSmsCodeF();
          this.addStep = 4;
        }
        if (step == 4) {
          addMerchant({
            shop_id: this.selectedShop.shop_id,
            register_id: this.selectedShop.id,
            mobile: this.selectedShop.mobile,
            code: this.smsCode,
          }).then((res) => {
            if (res.code == 1) {
              this.handleClose();
              this.$message.success("添加商家成功");
              this.getList();
            }
          });
        }
      },
      getSmsCodeF() {
        let _this = this;
        this.smsCodeText = "获取中···";
        getSmsCode({
          mobile: this.selectedShop.mobile,
        }).then((res) => {
          if (res.code == 1) {
            this.time = setInterval(function () {
              _this.num--;
              _this.smsCodeText = res.data.code;
              if (_this.num == 0) {
                clearInterval(_this.time);
                _this.num = 60;
              }

              _this.getSmsCodes(_this.smsCodeText);
            }, 1000);
          }
        });
      },
      getSmsCodes(value) {
        let _this = this;
        this.isError = false;

        if (value !== this.smsCodeText) {
          setTimeout(() => {
            _this.isError = true;
            _this.$message.error("验证码错误");
          }, 500);
        } else {
          this.smsCode = this.smsCodeText;
        }
      },
      handleClose() {
        this.dialogVisible = false;
        this.msg = "";
        this.addStep = 1;
      },
      toLogin() {
        console.log("要登录");
      },
      handleReqAuth(item) {
        authorization({
          id: item.id,
          type: 3,
        }).then((res) => {
          if (res.code == 1) {
            this.activeNames = '0'
            this.getList()
          }
        });

      }
    },

  };


</script>
<style lang="less">
  .sonHome {
    padding: 20px;

    .float {
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 22;
    }

    .floats {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 100;
    }

    .cell {
      padding: 20px 30px;
      background: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
      }

      .cancel-tab {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        padding: 20px 0;
        margin-top: 40px;

        .el-tabs__item {
          height: 50px;
        }

        .el-tabs__active-bar {
          height: 4px;
        }

        .el-tabs__nav-wrap::after {
          height: 1px;
        }

        .cancel-tab-one {
          .el-tabs__item {
            height: 40px;
          }
        }
      }

      .content {
        padding: 10px 0;

        .tishi {
          background: #fff7e0;
          border: 1px solid #ffbf00;
          padding: 20px;
          border-radius: 4px;
          margin-top: 30px;
          display: flex;
          font-size: 14px;
        }

        .list {
          background: #f6f6f6;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
          overflow: hidden;
          margin-top: 30px;

          .item {
            .title {
              background: #f6f6f6;
              display: flex;
              padding: 14px 30px 14px 50px;
              font-size: 14px;
              color: #666;
            }

            .nr {
              background: #fff;
              padding: 14px 30px 14px 14px;
              align-items: center;
              font-size: 14px;
              border-top: 1px solid #e5e5e5;
              border-bottom: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;

              .nr-left {
                display: flex;
                align-items: center;
              }

              .tag {
                background: #e8f9f7;
                border-radius: 12px;
                color: #1baf9d;
                padding: 4px 6px;
                font-size: 12px;
                display: flex;
                align-items: center;
              }
            }

            .nr-hidden {
              padding: 16px 30px 16px 50px;
              display: flex;
              justify-content: space-between;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .el-popover {
    min-width: 70px !important;

    .active:hover {
      color: #ee4d2d;
    }
  }
</style>